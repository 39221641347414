<template>
  <div class="content">
    <div class="content__title content__title--party">
      <h1 class="title title--big title--theme">Анкета руководителя региональной организации РСМ</h1>
      <div class="content__group">
        <button @click="onExport" type="button" class="link link--right">
          <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
          <span>Скачать Word</span>
        </button>
        <router-link v-if="userRole === 'admin'" :to="{ name: 'head-stat' }" class="link link--right" type="button">
          <img src="@/assets/img/sidebar/stat.svg" alt="Stat icon">
          <span>Статистика</span>
        </router-link>
        <button v-if="userRole === 'admin'" @click="openSidebar" class="link link--right" type="button">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7 17.0134L11.413 16.9984L21.045 7.45839C21.423 7.08039 21.631 6.57839 21.631 6.04439C21.631 5.51039 21.423 5.00839 21.045 4.63039L19.459 3.04439C18.703 2.28839 17.384 2.29239 16.634 3.04139L7 12.5834V17.0134ZM18.045 4.45839L19.634 6.04139L18.037 7.62339L16.451 6.03839L18.045 4.45839ZM9 13.4174L15.03 7.44439L16.616 9.03039L10.587 15.0014L9 15.0064V13.4174Z"
              fill="#2B93E7"/>
            <path
              d="M5 21H19C20.103 21 21 20.103 21 19V10.332L19 12.332V19H8.158C8.132 19 8.105 19.01 8.079 19.01C8.046 19.01 8.013 19.001 7.979 19H5V5H11.847L13.847 3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21Z"
              fill="#2B93E7"/>
          </svg>
          <span>Управление</span>
        </button>
      </div>
    </div>
    <div v-if="userRole === 'admin' && manage.comment_tech" class="comment">
      <div class="comment__info">
        {{ manage.comment_tech }}
      </div>
    </div>
    <form class="form" @submit.prevent="onCheckForm">
      <div class="form__content">
        <div class="form__part">
          <div class="form-group">
            <label>Фото</label>
            <cropper-modal
              @clearPhoto="onClearPhoto"
              :getCropCoords="getCropCoords"
              :getCropHash="getCropHash"
              :originalImgUrlProp="form.photo_url"
              :croppedImgUrlProp="form.crop_url"
              :stencilProps="{ aspectRatio: 3 / 4 }"
              ref="cropperModal"
            />
          </div>
          <div class="form-group">
            <label for="lastname">Фамилия</label>
            <div class="form-control">
              <input
                id="lastname"
                type="text"
                class="form-control__input"
                name="lastname"
                placeholder="Иванов"
                v-model.trim="form.lastname"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="firstname">Имя</label>
            <div class="form-control">
              <input
                id="firstname"
                type="text"
                class="form-control__input"
                name="firstname"
                placeholder="Иван"
                v-model.trim="form.firstname"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="patronymic">Отчество</label>
            <div class="form-control">
              <input
                id="patronymic"
                type="text"
                class="form-control__input"
                name="patronymic"
                placeholder="Иванович"
                v-model.trim="form.patronymic"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="dob">Дата рождения</label>
            <div class="form-control">
              <masked-input
                v-model="form.dob"
                name="dob"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="dob"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="form-group">
            <label>Пол</label>
            <div class="form-group__party">
              <label class="radio">
                <div class="radio__text">Мужской</div>
                <input type="radio" name="gender" v-model="form.gender" value="М"/>
                <div class="radio__radiomark"></div>
              </label>
              <label class="radio">
                <div class="radio__text">Женский</div>
                <input type="radio" name="gender" v-model="form.gender" value="Ж"/>
                <div class="radio__radiomark"></div>
              </label>
            </div>
          </div>
          <div class="form-group">
            <label for="post">Должность</label>
            <div class="form-control">
              <input
                id="post"
                type="text"
                class="form-control__input"
                name="post"
                placeholder="Иванович"
                v-model.trim="form.post"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="date_election">Дата избрания</label>
            <div class="form-control">
              <masked-input
                v-model="form.date_election"
                name="date_election"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="date_election"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="expiration_term">Дата окончания полномочий</label>
            <div class="form-control">
              <masked-input
                v-model="form.expiration_term"
                name="expiration_term"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="expiration_term"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="form-group">
            <label>Мобильный телефон</label>
            <vue-tel-input
              v-model="phone_value"
              defaultCountry="RU"
              mode="international"
              type="tel"
              class="phonepicker"
              :class="{ 'phonepicker--error': !is_phone_valid }"
              @validate="onValidatePhone"
              :inputOptions="phone_picker_options"
              validCharactersOnly
            >
              <template slot="arrow-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none">
                  <path
                    d="M4.96 6.75L.23 1.47a.93.93 0 010-1.22.71.71 0 011.09 0L5.5 4.92 9.68.25a.71.71 0 011.1 0c.3.34.3.88 0 1.22L6.03 6.75A.73.73 0 015.5 7c-.2 0-.4-.08-.54-.25z"
                    fill="#2B93E7"></path>
                </svg>
              </template>
            </vue-tel-input>
            <div class="form-group__error" v-if="!is_phone_valid">Недопустимое значение для номера телефона</div>
          </div>
          <div class="form-group">
            <label>Рабочий телефон / Факс</label>
            <vue-tel-input
              v-model="work_phone_value"
              defaultCountry="RU"
              mode="international"
              type="tel"
              class="phonepicker"
              :class="{ 'phonepicker--error': !is_work_phone_valid }"
              @validate="onValidateWorkPhone"
              :inputOptions="phone_picker_options"
              validCharactersOnly
            >
              <template slot="arrow-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none">
                  <path
                    d="M4.96 6.75L.23 1.47a.93.93 0 010-1.22.71.71 0 011.09 0L5.5 4.92 9.68.25a.71.71 0 011.1 0c.3.34.3.88 0 1.22L6.03 6.75A.73.73 0 015.5 7c-.2 0-.4-.08-.54-.25z"
                    fill="#2B93E7"></path>
                </svg>
              </template>
            </vue-tel-input>
            <div class="form-group__error" v-if="!is_work_phone_valid">Недопустимое значение для номера телефона</div>
          </div>
          <div class="form-group">
            <label for="email">Электронная почта</label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.email.$error }">
              <input
                id="email"
                type="email"
                name="email"
                class="form-control__input"
                placeholder="Введите электронную почту"
                v-model.trim="form.email"
                @change="$v.form.email.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.email.$dirty && !$v.form.email.email">
              Введите правильный формат почты
            </div>
          </div>
          <div class="form-group">
            <label for="year_from">Член РСМ с какого года</label>
            <div class="form-control">
              <input
                id="year_from"
                type="number"
                min="0"
                class="form-control__input"
                name="year_from"
                placeholder="Введите год"
                v-model.trim="form.year_from"
              />
            </div>
          </div>
          <div class="form-group">
            <label>Награды (государственные, ведомственные, РСМ, иные общественные награды)</label>
            <div class="form-table">
              <div class="form-table__head">
                <div class="form-table__item">Награда</div>
                <div class="form-table__item">Год</div>
                <div class="form-table__item">Тип награды</div>
              </div>
              <div
                v-for="(item, index) in form.awards"
                :key="index"
                class="form-table__row"
              >
                <button v-if="index > 0" @click="onDeleteItem(index, 'awards')" class="link link--absolute"
                        type="button">
                  <img src="@/assets/img/remove-icon.svg" alt="Remove icon">
                </button>
                <div class="form-table__item">
                  <input
                    :id="`reward${index}`"
                    type="text"
                    :name="`reward${index}`"
                    v-model.trim="item.reward"
                  />
                </div>
                <div class="form-table__item">
                  <masked-input
                    v-model="item.year"
                    name="date_election"
                    mask="1111"
                    :id="`year${index}`"
                    autocomplete="off"
                  />
                </div>
                <div class="form-table__item">
                  <input
                    :id="`type_reward${index}`"
                    type="text"
                    :name="`type_reward${index}`"
                    v-model.trim="item.type_reward"
                  />
                </div>
              </div>
            </div>
            <button @click="onCloneItem('awards', { reward: '', year: '', type_reward: '' })" class="link"
                    type="button">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
                <path
                  d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
                  fill="white"/>
              </svg>
              <span>Добавить строку</span>
            </button>
          </div>
          <div class="form-group">
            <label>Образование</label>
            <div class="form-table">
              <div class="form-table__head">
                <div class="form-table__item">Год окончания обучения</div>
                <div class="form-table__item">Учебное заведение</div>
                <div class="form-table__item">Специальность</div>
              </div>
              <div
                v-for="(item, index) in form.education"
                :key="index"
                class="form-table__row"
              >
                <button v-if="index > 0" @click="onDeleteItem(index, 'education')" class="link link--absolute"
                        type="button">
                  <img src="@/assets/img/remove-icon.svg" alt="Remove icon">
                </button>
                <div class="form-table__item">
                  <masked-input
                    v-model="item.year"
                    name="date_election"
                    mask="1111"
                    :id="`year${index}`"
                    autocomplete="off"
                  />
                </div>
                <div class="form-table__item">
                  <input
                    :id="`edu_place${index}`"
                    type="text"
                    :name="`edu_place${index}`"
                    v-model.trim="item.edu_place"
                  />
                </div>
                <div class="form-table__item">
                  <input
                    :id="`spec${index}`"
                    type="text"
                    :name="`spec${index}`"
                    v-model.trim="item.spec"
                  />
                </div>
              </div>
            </div>
            <button @click="onCloneItem('education', { year: '', edu_place: '', spec: '' })" class="link" type="button">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
                <path
                  d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
                  fill="white"/>
              </svg>
              <span>Добавить строку</span>
            </button>
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <label>Ученая степень, звание</label>
            <div class="form-group form-group--minindent" v-for="(item, index) in form.academic" :key="index">
              <div class="form-control">
                <input
                  :id="`edu_rank${index}`"
                  type="text"
                  class="form-control__input"
                  :name="`edu_rank${index}`"
                  v-model.trim="item.edu_rank"
                />
                <button v-if="index > 0" @click="onDeleteItem(index, 'academic')" class="link link--absolute"
                        type="button">
                  <img src="@/assets/img/remove-icon.svg" alt="Remove icon">
                </button>
              </div>
            </div>
            <button @click="onCloneItem('academic', { edu_rank: '' })" class="link" type="button">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
                <path
                  d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
                  fill="white"/>
              </svg>
              <span>Добавить строку</span>
            </button>
          </div>
          <div class="form-group">
            <label>Дополнительное образование и повышение квалификации</label>
            <div class="form-group form-group--minindent" v-for="(item, index) in form.education_add" :key="index">
              <div class="form-control">
                <input
                  :id="`education_add${index}`"
                  type="text"
                  class="form-control__input"
                  :name="`education_add${index}`"
                  v-model.trim="item.edu_another"
                />
                <button v-if="index > 0" @click="onDeleteItem(index, 'education_add')" class="link link--absolute"
                        type="button">
                  <img src="@/assets/img/remove-icon.svg" alt="Remove icon">
                </button>
              </div>
            </div>
            <button @click="onCloneItem('education_add', { edu_rank: '' })" class="link" type="button">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
                <path
                  d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
                  fill="white"/>
              </svg>
              <span>Добавить строку</span>
            </button>
          </div>
          <div class="form-group">
            <label>Владение иностранными языками</label>
            <div class="form-table form-table--double">
              <div class="form-table__head">
                <div class="form-table__item">Язык</div>
                <div class="form-table__item">Уровень владения</div>
              </div>
              <div
                v-for="(item, index) in form.languages"
                :key="index"
                class="form-table__row"
              >
                <button v-if="index > 0" @click="onDeleteItem(index, 'languages')" class="link link--absolute"
                        type="button">
                  <img src="@/assets/img/remove-icon.svg" alt="Remove icon">
                </button>
                <div class="form-table__item">
                  <v-select
                    v-model="item.language"
                    :searchable="false"
                    :clearable="false"
                    :options="lang_options"
                    :get-option-label="getLabel"
                    placeholder="Выберите язык"
                    class="select select--no-border"
                  >
                    <template slot="open-indicator">
                      <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                        <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                      </svg>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="select__item d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                </div>
                <div class="form-table__item">
                  <v-select
                    v-model="item.level"
                    :searchable="false"
                    :clearable="false"
                    :options="level_options"
                    :get-option-label="getLabel"
                    placeholder="Выберите уровень владения"
                    class="select select--no-border"
                  >
                    <template slot="open-indicator">
                      <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                        <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                      </svg>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="select__item d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <button @click="onCloneItem('languages', { language: '', level: '' })" class="link" type="button">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
                <path
                  d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
                  fill="white"/>
              </svg>
              <span>Добавить строку</span>
            </button>
          </div>
          <div class="form-group">
            <div class="content__title">
              <h2 class="title">Профессиональный опыт</h2>
            </div>
            <div class="form-group form-group--minindent" v-for="(item, index) in form.experience" :key="index">
              <div class="form-group form-group--minindent">
                <div class="form-group__party form-group__party--between">
                  <label :for="`from${index}`">Год начала работы</label>
                  <button
                    v-if="index > 0"
                    @click="onDeleteItem(index, 'experience')"
                    class="form-group__btn form-group__btn--red"
                    type="button"
                  >
                    Удалить строки
                  </button>
                </div>
                <div class="form-control">
                  <input
                    :id="`from${index}`"
                    type="text"
                    class="form-control__input"
                    :name="`from${index}`"
                    v-model.trim="item.from"
                  />
                </div>
              </div>
              <div class="form-group form-group--minindent">
                <div class="form-control">
                  <label :for="`to${index}`">Год окончания работы (Оставьте пустым, если по настоящее время)</label>
                  <input
                    :id="`to${index}`"
                    type="text"
                    class="form-control__input"
                    :name="`to${index}`"
                    v-model.trim="item.to"
                  />
                </div>
              </div>
              <div class="form-group form-group--minindent">
                <div class="form-control">
                  <label :for="`place${index}`">Место работы</label>
                  <input
                    :id="`place${index}`"
                    type="text"
                    class="form-control__input"
                    :name="`place${index}`"
                    v-model.trim="item.place"
                  />
                </div>
              </div>
              <div class="form-group form-group--minindent">
                <div class="form-control">
                  <label :for="`post${index}`">Должность</label>
                  <input
                    :id="`post${index}`"
                    type="text"
                    class="form-control__input"
                    :name="`post${index}`"
                    v-model.trim="item.post"
                  />
                </div>
              </div>
            </div>
            <button @click="onCloneItem('experience', { from: '', to: '', place: '', post: '' })" class="link"
                    type="button">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
                <path
                  d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
                  fill="white"/>
              </svg>
              <span>Добавить строки</span>
            </button>
          </div>
          <div class="form-group">
            <label>Участие в совещательных, консультативных и экспертных советах при органах законодательной и
              исполнительной властей федерального, межрегионального, регионального уровней, в
              Общественных палатах (советах) региона, РФ</label>
            <div class="form-table">
              <div class="form-table__head">
                <div class="form-table__item">Год избрания</div>
                <div class="form-table__item">Год окончания</div>
                <div class="form-table__item">Наименование органа</div>
              </div>
              <div
                v-for="(item, index) in form.participation"
                :key="index"
                class="form-table__row"
              >
                <button v-if="index > 0" @click="onDeleteItem(index, 'participation')" class="link link--absolute"
                        type="button">
                  <img src="@/assets/img/remove-icon.svg" alt="Remove icon">
                </button>
                <div class="form-table__item">
                  <masked-input
                    v-model="item.year_start"
                    name="date_election"
                    mask="1111"
                    :id="`year_start${index}`"
                    autocomplete="off"
                  />
                </div>
                <div class="form-table__item">
                  <masked-input
                    v-model="item.year_end"
                    name="date_election"
                    mask="1111"
                    :id="`year_end${index}`"
                    autocomplete="off"
                  />
                </div>
                <div class="form-table__item">
                  <input
                    :id="`name${index}`"
                    type="text"
                    :name="`name${index}`"
                    v-model.trim="item.name"
                  />
                </div>
              </div>
            </div>
            <button @click="onCloneItem('participation', { year_start: '', year_end: '', name: '' })" class="link"
                    type="button">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
                <path
                  d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
                  fill="white"/>
              </svg>
              <span>Добавить строку</span>
            </button>
          </div>
          <div class="form-group">
            <label>Членство в иных НКО (Кроме РСМ)</label>
            <div class="form-group form-group--minindent" v-for="(item, index) in form.participation_other" :key="index">
              <div class="form-control">
                <input
                  :id="`another_member${index}`"
                  type="text"
                  class="form-control__input"
                  :name="`another_member${index}`"
                  v-model.trim="item.another_member"
                />
                <button v-if="index > 0" @click="onDeleteItem(index, 'participation_other')" class="link link--absolute"
                        type="button">
                  <img src="@/assets/img/remove-icon.svg" alt="Remove icon">
                </button>
              </div>
            </div>
            <button @click="onCloneItem('participation_other', { another_member: '' })" class="link" type="button">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
                <path
                  d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
                  fill="white"/>
              </svg>
              <span>Добавить строку</span>
            </button>
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button class="button" type="submit" :class="{ 'preload': loading }">
          <span>Сохранить</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
    <SidebarManage
      :title="'Управление'"
      :class="{'sidebar-manage--full': show_sidebar}"
      @close-sidebar="show_sidebar = !show_sidebar"
    >
      <form>
        <div class="form-group">
          <label for="comment_tech">Служебный комментарий</label>
          <div class="form-control">
            <textarea
              @change="sendManage"
              id="comment_tech"
              name="comment_tech"
              class="form-control__textarea"
              v-model.trim="manage.comment_tech"
              placeholder="Видят только администраторы"
            >
            </textarea>
          </div>
        </div>
      </form>
      <div class="sidebar-card">
        <div class="sidebar-card__item sidebar-card__item--between">
          <b>Дата создания</b> <span>{{ form.created_at }}</span>
        </div>
        <div class="sidebar-card__item sidebar-card__item--between">
          <b>Кем создано</b> <span>{{ form.created_name }}</span>
        </div>
        <div class="sidebar-card__item sidebar-card__item--between">
          <b>Дата обновления</b> <span>{{ form.updated_at }}</span>
        </div>
        <div class="sidebar-card__item sidebar-card__item--between">
          <b>Кем обновлено</b> <span>{{ form.updated_name || '-' }}</span>
        </div>
      </div>
    </SidebarManage>
  </div>
</template>

<script>
import {omit} from 'lodash'
import {validationMixin} from 'vuelidate'
import {email} from 'vuelidate/lib/validators'
import MaskedInput from 'vue-masked-input'
import CropperModal from '@/components/Cropper'
import SidebarManage from '@/components/SidebarManage'
import {VueTelInput} from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
  name: 'HeadForm',
  components: {
    MaskedInput,
    CropperModal,
    VueTelInput,
    SidebarManage,
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {email},
    },
  },
  data() {
    return {
      form: {
        coords: {},
        awards: [
          {
            reward: '',
            year: '',
            type_reward: ''
          }
        ],
        education: [
          {
            year: '',
            edu_place: '',
            spec: ''
          }
        ],
        academic: [
          {
            edu_rank: ''
          }
        ],
        education_add: [
          {
            edu_another: ''
          }
        ],
        languages: [
          {
            language: '',
            level: ''
          }
        ],
        experience: [
          {
            from: '',
            to: '',
            place: '',
            post: ''
          }
        ],
        participation: [
          {
            year_start: '',
            year_end: '',
            name: ''
          }
        ],
        participation_other: [
          {
            another_member: ''
          }
        ],
      },
      manage: {},
      new_head: false,
      show_sidebar: false,
      loading: false,
      status_options: [],
      lang_options: [],
      level_options: [],
      phone_value: '',
      is_phone_valid: true,
      work_phone_value: '',
      is_work_phone_valid: true,
      phone_picker_options: {
        placeholder: 'Введите номер телефона'
      },
    }
  },
  computed: {
    userRegion() {
      return this.$store.state.organization
    },
    userRole() {
      return this.$store.state.userRole
    }
  },
  mounted() {
    this.fetchForm()
    if (this.userRegion) {
      this.form.region_org = this.userRegion
      this.manage.region_org = this.userRegion
    }
    this.$store.dispatch('head/GET_LANG')
      .then(response => {this.lang_options = response.data})
    this.$store.dispatch('head/GET_LEVEL')
      .then(response => {this.level_options = response.data})
  },
  methods: {
    fetchForm() {
      this.$store.dispatch('head/GET_DATA')
        .then(response => {
          this.form = {...this.form, ...response.data}
          this.phone_value = response.data.phone ?? ''
          this.work_phone_value = response.data.work_phone ?? ''
          let {comment_tech} = response.data
          this.manage = {comment_tech}
        })
        .catch(() => {
          this.new_head = true
        })
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        if (this.form.coords.hasOwnProperty('width') && !this.form.photo) {
          this.$refs.cropperModal.sendCrop()
            .then(() => {
              this.sendForm()
            })
        } else {
          this.sendForm()
        }
      }
    },
    sendForm() {
      if (!this.form.date_election) {
        this.form = omit(this.form, ['date_election'])
      }
      if (!this.form.dob) {
        this.form = omit(this.form, ['dob'])
      }
      if (!this.form.expiration_term) {
        this.form = omit(this.form, ['expiration_term'])
      }
      if (this.new_head) {
        this.loading = true
        this.$store.dispatch('head/POST_DATA', this.form)
          .then(() => {
            this.loading = false
            this.$notify({
              type: 'success',
              title: 'Успех!',
              text: 'Изменения сохранены.'
            });
          })
          .catch(() => {
            this.loading = false
            this.$notify({
              type: 'error',
              title: 'Ошибка!',
              text: 'Ошибка сохранения.'
            })
          })
      } else {
        this.loading = true
        this.$store.dispatch('head/PATCH_DATA', this.form)
          .then(() => {
            this.loading = false
            this.$notify({
              type: 'success',
              title: 'Успех!',
              text: 'Изменения сохранены.'
            });
          })
          .catch(() => {
            this.loading = false
            this.$notify({
              type: 'error',
              title: 'Ошибка!',
              text: 'Ошибка сохранения.'
            })
          })
      }
    },
    sendManage() {
      this.form = {...this.form, ...this.manage}
      this.$store.dispatch('head/PATCH_DATA', this.manage)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Изменения сохранены.'
          });
        })
        .catch(() => {
          this.loading = false
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка сохранения.'
          });
        });
    },
    onClearPhoto() {
      this.form.photo = ''
      this.form.coords = []
    },
    getCropCoords(value) {
      this.form.coords = value
    },
    getCropHash(value) {
      this.form.photo = value
    },
    onValidatePhone(number) {
      this.is_phone_valid = number.valid
      if (number.valid && number.number !== '') {
        this.form.phone = number.number
      } else {
        this.form.phone = ''
      }
      if (!number.hasOwnProperty('number')) {
        this.is_phone_valid = true
      }
    },
    onValidateWorkPhone(number) {
      this.is_work_phone_valid = number.valid
      if (number.valid && number.number !== '') {
        this.form.work_phone = number.number
      } else {
        this.form.work_phone = ''
      }
      if (!number.hasOwnProperty('number')) {
        this.is_work_phone_valid = true
      }
    },
    onCloneItem(name, obj) {
      this.form[name].push(obj)
    },
    onDeleteItem(index, name) {
      this.form[name].splice(index, 1);
    },
    openSidebar() {
      this.show_sidebar = true
    },
    getLabel (option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    onExport() {
      this.$store.dispatch('head/GET_EXPORT')
        .then(response => {
          const url = URL.createObjectURL(response.data)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Анкета_руководителя_${this.userRegion}.docx`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(error => {
          error.response.data.text().then(res => {
            const errorJson = JSON.parse(res)
            for (const key in errorJson) {
              if (typeof errorJson[key] === 'string') {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: errorJson[key]
                })
              } else {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: errorJson[key][0]
                })
              }
            }
          })
        })
    }
  }
}
</script>
